import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import permission from "./permission";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    base_url: "https://hr.citsmp.com/",
    token: localStorage.getItem("token") || null,
    user_name: localStorage.getItem("user_name") || null,
    role_name: localStorage.getItem("role_name") || null,

    getEmployee: {},
    getUserType: {},
    getTasks: {},
    getDepartment: {},
    getProjectTask: {},
    getPermissions: {},
    getModuleWiseRole: {},
    getModuleWiseDepartment: {},
    getDesignation: {},
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    getEmployee(state) {
      return state.getEmployee;
    },
    getDepartment(state) {
      return state.getDepartment;
    },
    getUserType(state) {
      return state.getUserType;
    },
    getTasks(state) {
      return state.getTasks;
    },
    getProjectTask(state) {
      return state.getProjectTask;
    },
    getPermissions(state) {
      return state.getPermissions;
    },
    getModuleWiseRole(state) {
      return state.getModuleWiseRole;
    },
    getModuleWiseDepartment(state) {
      return state.getModuleWiseDepartment;
    },
    getDesignation(state) {
      return state.getDesignation;
    },
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token;
      state.user_name = data.name;
      state.role_name = data.role_name;
    },
    removeToken(state) {
      state.token = null;
      state.user_name = null;
      state.role_name = null;
    },

    getEmployee(state, data) {
      return (state.getEmployee = data);
    },
    getDepartment(state, data) {
      return (state.getDepartment = data);
    },
    getUserType(state, data) {
      return (state.getUserType = data);
    },
    getTasks(state, data) {
      return (state.getTasks = data);
    },
    getProjectTask(state, data) {
      return (state.getProjectTask = data);
    },
    getPermissions(state, data) {
      return (state.getPermissions = data);
    },
    getModuleWiseRole(state, data) {
      return (state.getModuleWiseRole = data);
    },
    getModuleWiseDepartment(state, data) {
      return (state.getModuleWiseDepartment = data);
    },
    getDesignation(state, data) {
      return (state.getDesignation = data);
    },
  },
  actions: {
    removeToken(context) {
      context.commit("removeToken");
    },
    setToken(context, data) {
      context.commit("setToken", data);
    },

    CheckAuth(content) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/check")
        .then((res) => {
          if (!res.data.status) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        });
    },

    getEmployee(content, department_id = []) {
      axios
        .post("/app/departments/get/all/employee", {
          department_id: department_id,
        })
        .then((res) => {
          content.commit("getEmployee", res.data.employees);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDepartment(content) {
      axios
        .post("/app/get/departments/all")
        .then((res) => {
          content.commit("getDepartment", res.data.departments);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getUserType(content) {
      axios
        .post("/app/departments/get/all/user/type")
        .then((res) => {
          content.commit("getUserType", res.data.user_types);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTasks(content) {
      axios
        .post("/app/project/get/default/tasks/all")
        .then((res) => {
          content.commit("getTasks", res.data.default_tasks);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getProjectTask(content, id) {
      axios
        .post("/app/project/get/project/wise/task/" + id)
        .then((res) => {
          content.commit("getProjectTask", res.data.tasks);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getModuleWiseRole(content, id) {
      axios
        .post("/app/modules/wise/role/" + id)
        .then((res) => {
          content.commit("getModuleWiseRole", res.data.roles);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getModuleWiseDepartment(content, id) {
      axios
        .post("/app/get/module/wise/departments/" + id)
        .then((res) => {
          content.commit("getModuleWiseDepartment", res.data.departments);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDesignation(content) {
      axios
        .post("/app/designations/get/all")
        .then((res) => {
          content.commit("getDesignation", res.data.designations);
        })
        .catch((e) => {
          console.log(e);
        });
    },

  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    permission,
  },
  strict: process.env.DEV,
});
