export default {
  state: {},
  getters: {},
  actions: {
    getPermissions(content) {
      axios
        .post("/app/permission")
        .then((res) => {
          let permissions = {
            default_task: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },

            project: {
              access: false,
              all_access: false,
              create: false,
              edit: false,
              delete: false,
            },

            main_task: {
              access: false,
              all_access: false,
              create: false,
              edit: false,
              delete: false,
            },

            task: {
              access: false,
              all_access: false,
              create: false,
              edit: false,
              delete: false,
              manage: false,
            },

            user: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            role: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            department: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
            designation: {
              access: false,
              create: false,
              edit: false,
              delete: false,
            },
          };
          res.data.permissions.forEach((permission) => {
             // Default Task Permission
            if (permission.slug == "app.default.task.access") {
              permissions.default_task.access = true;
            }
            if (permission.slug == "app.default.task.create") {
              permissions.default_task.create = true;
            }
            if (permission.slug == "app.default.task.edit") {
              permissions.default_task.edit = true;
            }
            if (permission.slug == "app.default.task.destroy") {
              permissions.default_task.delete = true;
            }

            // Project Permission
            if (permission.slug == "app.project.access") {
              permissions.project.access = true;
            }
            if (permission.slug == "app.project.create") {
              permissions.project.create = true;
            }
            if (permission.slug == "app.project.edit") {
              permissions.project.edit = true;
            }
            if (permission.slug == "app.project.destroy") {
              permissions.project.delete = true;
            }
            if (permission.slug == "app.project.all.access") {
              permissions.project.all_access = true;
            }

            // Main Task Permission
            if (permission.slug == "app.main.task.access") {
              permissions.main_task.access = true;
            }
            if (permission.slug == "app.main.task.create") {
              permissions.main_task.create = true;
            }
            if (permission.slug == "app.main.task.edit") {
              permissions.main_task.edit = true;
            }
            if (permission.slug == "app.main.task.destroy") {
              permissions.main_task.delete = true;
            }
            if (permission.slug == "app.main.task.all.access") {
              permissions.main_task.all_access = true;
            }

            // Task Permission
            if (permission.slug == "app.task.access") {
              permissions.task.access = true;
            }
            if (permission.slug == "app.task.create") {
              permissions.task.create = true;
            }
            if (permission.slug == "app.task.edit") {
              permissions.task.edit = true;
            }
            if (permission.slug == "app.task.destroy") {
              permissions.task.delete = true;
            }
            if (permission.slug == "app.task.manage") {
              permissions.task.manage = true;
            }
            if (permission.slug == "app.task.all.access") {
              permissions.task.all_access = true;
            }

            // User Permission
            if (permission.slug == "app.user.access") {
              permissions.user.access = true;
            }
            if (permission.slug == "app.user.create") {
              permissions.user.create = true;
            }
            if (permission.slug == "app.user.edit") {
              permissions.user.edit = true;
            }
            if (permission.slug == "app.user.destroy") {
              permissions.user.delete = true;
            }

            // Role Permission
            if (permission.slug == "app.role.access") {
              permissions.role.access = true;
            }
            if (permission.slug == "app.role.create") {
              permissions.role.create = true;
            }
            if (permission.slug == "app.role.edit") {
              permissions.role.edit = true;
            }
            if (permission.slug == "app.role.destroy") {
              permissions.role.delete = true;
            }

            // Department
            if (permission.slug == "app.department.access") {
              permissions.department.access = true;
            }
            if (permission.slug == "app.department.create") {
              permissions.department.create = true;
            }
            if (permission.slug == "app.department.edit") {
              permissions.department.edit = true;
            }
            if (permission.slug == "app.department.destroy") {
              permissions.department.delete = true;
            }

            // Designation
            if (permission.slug == "app.designation.access") {
              permissions.designation.access = true;
            }
            if (permission.slug == "app.designation.create") {
              permissions.designation.create = true;
            }
            if (permission.slug == "app.designation.edit") {
              permissions.designation.edit = true;
            }
            if (permission.slug == "app.designation.destroy") {
              permissions.designation.delete = true;
            }
          });
          content.commit("getPermissions", permissions);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
